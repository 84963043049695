
$border-light:    rgba(0, 0, 0, .3);
$border-lighter:  rgba(0, 0, 0, .2);
$border-lightest: rgba(0, 0, 0, .1);
//color for sandbox #dfba13
//color for production: #1391df
//color for pgd: #124b6f
$colors: (
	primary: (
		base:    #1391df,
		dark:    #0b81ca,
		light:   #20a0f0,
		active:  #0298f5,
		darker:  #006db1,
		darkest: #00609c,
	),
	secondary: (
		base:   #e86831,
		dark:   #d45925,
		darker: #bd4917,
		light:  #fc7941,
	),
	tertiary: (
		base:  #7f2f9f,
		dark:  #70248f,
		light: #963fb8,
	),
	quaternary: (
		base:  #4c57c0,
		dark:  #3e4ab4,
		light: #5e6ad3,
	),
	success: (
		base:  #21b879,
		dark:  #13a568,
		light: #2fd18e,
	),
	warning: (
		base:  #EE3F2F,
		dark:  #D6372C,
		light: #f74f40,
	),
	delete: (
		base:  #d60909,
		dark:  #be0000,
		light: #f01919,
	),
	rejected: (
		base:  #dd4350,
		dark:  #d43946,
		light: #e44f5b,
	),
	expired: (
		base:  #ffa333,
		dark:  #f3992b,
		light: #ffa940,
	),
	pending: (
		base:  #ff8533,
		dark:  #f57825,
		light: #ff8e42,
	),
	accent: (
		base:  #49a6f7,
		dark:  #3998eb,
		light: #60b5ff,
	),
	contrast: (
		base:  #8f21e3,
		dark:  #7011b8,
		light: #9a37e6,
	),
	gray: (
		base:     #a4a4a4,
		dark:     #909090,
		light:    #adadad,
		lighter:  #c0c0c0,
		lightest: #cfcfcf,
	),
	neutral: (
		base:  #bbc2de,
		dark:  #a5adce,
		light: #cbd1e6,
	),
	text: (
		base:  #545d8e,
		dark:  #404879,
		light: #6a72a0,
	),
	light: (
		base:  #f1f3f8,
		dark:  #d5d9e4,
		light: #fff,
	),
	inactive: (
		base:  #cbd1e5,
		dark:  #b5bcd4,
		light: #dfe4f3,
	),
	dark: (
		base:  #474f6b,
		dark:  #38405c,
		light: #585f7a,
	),
	background: (
		base:    #E7F2FD,
		dark:    #d7e6f5,
		light:   #f1f8ff,
		lighter: hsl(210, 100%, 99%),
	),
	back: (
		base:  #f5f6fa,
		dark:  #e3e5ee,
		light: #ffffff,
	),
	border: (
		base:  #d6daea,
		dark:  #c8cde0,
		light: #dfe3f0,
	),
	tableborder: (
		base:  #ddd,
		dark:  #ccc,
		light: #eee,
	),
	shadow: (
		base:  rgba(0, 0, 0, 0.10),
		dark:  rgba(0, 0, 0, 0.15),
		light: rgba(0, 0, 0, 0.05),
	),
	notification: (
		base:    #1c9fec,
		light:   #25aaf7,
		dark:    #1897e0,
		darker:  #1796df,
		darkest: #1897e0,
	),
	res-reserved: (
		base:  #f1bd63,
		dark:  #ecb960,
		light: #fdc665,
	),
	res-progress: (
		base:  #98d1f0,
		dark:  #90c6e4,
		light: #a2dfff,
	),
	res-peding-grade: (
		base:  #455a66,
		dark:  #455a66,
		light: #455a66,
	),
	res-graded: (
		base:  #5681b6,
		dark:  #527aac,
		light: #5c8ac4,
	),
	res-invoice: (
		base:  #a8d384,
		dark:  #9ac279,
		light: #afdd89,
	),
	res-completed: (
		base:  #bdb7c6,
		dark:  #b1abb9,
		light: #c8c1d3,
	),
	res-nofly: (
		base:  #f96251,
		dark:  #e65a4b,
		light: #ff6452,
	),
	res-pre-enroll: (
		base:  #976d68,
		dark:  #7e5a56,
		light: #ad7e79,
	),
	res-other-location: (
		base:  #455a66,
		dark:  #455a66,
		light: #455a66,
	),
);

@function color($color-name, $color-variant:null) {
	@if ($color-variant != null) {
		@return map-get(map-get($colors, $color-name), $color-variant);
	}

	@else {
		@return map-get(map-get($colors, $color-name), base);
	}
}

// ------------------------------- COMMON CLASSES ------------------------------

.border-left {
	border-left: 2px solid color(primary, dark);
}

.box-shadow {
	box-shadow: 0 .2rem .75rem 0 color(shadow), 0 .25rem 1rem 0 color(shadow);
}

.success {
	color: color(success) !important;
}

.bg-success {
	background-color: color(success) !important;
}

.warning {
	color: color(warning) !important;
}
