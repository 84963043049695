@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
@import "./assets/styles/colors";
@import "./assets/styles/responsive";
@import "./assets/styles/buttons";
@import "material-icons/iconfont/material-icons.css";
@import "ngx-drag-to-select/scss/ngx-drag-to-select";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
/*
@import '../node_modules/material-icons/iconfont/material-icons.scss';
@import '../node_modules/ngx-drag-to-select/scss/ngx-drag-to-select';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
*/
//@use './assets/fonts/fontawesome-webfont.woff2';
//@use './assets/fonts/fontawesome-webfont.woff';
//@use './assets/fonts/fontawesome-webfont.ttf';

/****** Elad Shechter's RESET *******/
/*** box sizing border-box for all elements ***/

/*
*,
:not(.angular-editor-toolbar-b)::before,
:not(.angular-editor-toolbar-b)::after{
	box-sizing: border-box;
	outline: none;
	font-family: 'Montserrat', sans-serif;
}
*/

/*
.angular-editor-toolbar-b{
	font-family: FontAwesome !important;
	color: #474d6d !important;
}
*/
*,
:not(.fa)::before,
:not(.fa)::after {
	box-sizing: border-box;
	outline: none;
	font-family: "Montserrat", sans-serif;
}

angular-editor-toolbar button i {
	font-family: FontAwesome !important;
}

.angular-editor-toolbar {
	border-color: #1591df !important;
}

div,
button,
h1,
h2,
h3,
label,
p,
section,
a {
	user-select: none;
}

input {
	user-select: text;
}

/*
	TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.
	*/

.mat-option-text {
	font-size: 1rem;
}

/*.mdc-list-item__primary-text {
		font-size: 1rem;
	}*/

.arc {
	stroke: #f1f3f7 !important;
	stroke-width: 4 !important;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}

button {
	background-color: transparent;
	color: inherit;
	border-width: 0;
	padding: 0;
	cursor: pointer;
	font-size: 1rem;
}

figure {
	margin: 0;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
	margin: 0;
}

ul,
ol,
dd {
	margin: 0;
	padding: 0;
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-size: inherit;
	font-weight: inherit;
}

h1:has(.angular-editor-textarea) {
	margin: 10px;
}

.angular-editor-textarea {
	border-color: #1591df !important;

	ol {
		margin-top: 0;
		margin-bottom: 1rem;
		padding-left: 2rem;
		list-style: decimal;
	}

	ul {
		margin-top: 0;
		margin-bottom: 1rem;
		padding-left: 2rem;
		list-style: disc;
	}
}

p {
	margin: 0;
}

cite {
	font-style: normal;
}

fieldset {
	border-width: 0;
	padding: 0;
	margin: 0;
}

html,
body {
	height: 100%;
}

body {
	margin: 0;
}

// added by material

// @import '~simplebar/dist/simplebar.css';

html {
	font-size: 1rem;
	/*for using REM units*/

	@include res-above($small) {
		font-size: 0.7rem;
	}

	@include res-above($medium) {
		font-size: 0.8rem;
	}

	@include res-above($large) {
		font-size: 0.8rem;
	}

	@include res-above($xlarge) {
		font-size: 0.8rem;
	}
}

body {
	font-family: "Montserrat", sans-serif;
	margin: 0;
	color: color(text);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.3;
}

.spaced {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

/*
	TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
	*/
.mat-tab-label {
	min-width: auto !important;
	padding: 0rem 1rem !important;
}

/*
	TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
	*/
.mat-tab-labels {
	justify-content: space-between;
}

mat-label {
	color: color(primary);
	// font-weight: bold;
	font-weight: 600;
	font-size: 0.9rem;
}

/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
.mat-select-disabled {
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	.mat-select-arrow-wrapper {
		display: none !important;
	}
}

/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
.mat-select-placeholder {
	color: color(gray) !important;
	font-size: 0.9rem;
	font-style: italic !important;
}

/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
mat-select,
input {
	background-color: color(light);
	border: none;
	padding: 1rem;
	border-radius: 0.5rem;
	font-size: 1rem;
	width: 100%;

	&::placeholder {
		font-style: italic;
		color: #b3bacd;
	}
	/*
	TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.
	*/
	.mat-select-value-text,
	.mat-option-text {
		color: color(text);
	}
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	.mat-mdc-select-trigger {
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0.85rem 1.25rem;
	}
}

.cdk-overlay-pane {
	justify-content: center;
}

.mat-mdc-select {
	letter-spacing: normal !important;
	//font-weight: 900 !important;
	color: color(text) !important;

	.mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-value-text {
		font-size: 1rem;
		font-weight: 400;
		color: #404879;
	}
	//Adding disabled styling to mdc select
	&.mat-mdc-select-disabled {
		background-color: #dfe4f3 !important;
		border: 0.1rem solid #b5bcd4 !important;
		color: #545d8e !important;
		cursor: not-allowed !important;
	}
}

nav.section-navigation {
	text-align: center;
	margin-bottom: 1rem;

	.mat-mdc-select {
		.mat-mdc-select-trigger .mat-mdc-select-value {
			text-align: left;
			.mat-mdc-select-value-text {
				font-size: 1.2rem;
				font-weight: 600;
				color: color(light, light);
			}
		}
	}
}

mat-select.mat-mdc-select.view-select {
	.mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-value-text {
		font-size: unset;
		font-weight: 700;
		color: #404879;
	}
}

div.mat-mdc-select-panel {
	transform-origin: 50% 18.3594px 0px;
	transform: scaleY(1);
	//min-width: fit-content;
	//max-width: 280px;
	//min-height: fit-content;
	//max-height: unset;
	padding: 0 !important;
	border-top-left-radius: 1rem !important;
	border-top-right-radius: 1rem !important;
	border-bottom-left-radius: 1rem !important;
	border-bottom-right-radius: 1rem !important;

	.mat-mdc-option {
		&.mdc-list-item {
			font-family: inherit;
			font-size: inherit;
			line-height: 1.5;
			padding: 0.5rem 1rem 0.5rem 2rem;
			min-height: fit-content;

			&.mat-mdc-option-active {
				//background-color: color(secondary);

				.mdc-list-item__primary-text {
					//color: color(light, light);
				}
			}
			//Modify text highlighting for SelectInputComponent
			&.incomplete {
				//background-color: unset;
				border-style: unset;
				margin-bottom: 0.2rem;

				&:hover {
					background-color: color(light);
				}

				&.mat-mdc-option-active {
					//background-color: color(secondary);

					.mdc-list-item__primary-text {
						//color: color(light, light);
					}
				}
			}
		}

		.mdc-list-item__primary-text {
			font-weight: 500;
			//text-wrap: nowrap;
		}

		mat-pseudo-checkbox {
			display: none;
		}
	}
}

div.col {
	button.main-asset-button {
		font-weight: 600;
	}
}

div.col {
	button.secondary-asset-button {
		display: flex;
		flex-direction: row;
		text-wrap: nowrap;
		font-weight: 600;
	}
}

div.mat-mdc-autocomplete-panel {
	border-top-left-radius: 1rem !important;
	border-top-right-radius: 1rem !important;
	border-bottom-left-radius: 1rem !important;
	border-bottom-right-radius: 1rem !important;

	.mat-mdc-option {
		&.mdc-list-item {
			font-family: inherit;
			font-size: inherit;
			line-height: 1.5;
			padding: 0.5rem 2rem;
			min-height: fit-content;

			&.mdc-list-item--selected {
				background-color: color(secondary);

				.mdc-list-item__primary-text {
					//color: color(light, light);
				}
			}
		}

		.mdc-list-item__primary-text {
			font-weight: 510;
			//text-wrap: nowrap;
		}

		mat-pseudo-checkbox {
			display: none;
		}
	}
}

.mat-select-panel mat-option.mat-mdc-option {
	height: unset !important;
}

/*
	TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.
	*/
.mat-option-text.mat-option-text {
	white-space: normal !important;
	line-height: 1.5 !important;
	padding: 0.6rem 0 !important;
}

.info .info-header mat-select .mat-mdc-select-trigger {
	padding: 0.85rem 1.25rem;
}

.rounded-select {
	position: relative;
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	mat-select {
		background-color: color(primary);
		border-radius: 1.5rem;
		min-height: 3rem;

		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		.mat-mdc-select-trigger {
			padding: 0.85rem 1.25rem;

			.mat-mdc-select-value {
				font-weight: 600;
			}
		}
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		.mat-mdc-select-min-line {
			color: color(light, light);
			font-size: 1rem;
		}
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		.mat-select-trigger {
			/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
			/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
			.mat-select-value {
				line-height: 1.5;
			}
		}
	}

	&.rounded-select--white {
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		mat-select {
			background-color: white;
			/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
			/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
			.mat-mdc-select-min-line {
				color: color(primary);
			}
		}
	}

	&.rounded-select--primary-bg-shadow {
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		mat-select {
			background-color: color(primary);
			box-shadow:
				0 0.2rem 0.75rem 0 color(primary, dark),
				0 0.25rem 1rem 0 color(primary, dark);
		}
	}

	&.rounded-select--secondary-bg-shadow {
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		mat-select {
			background-color: color(secondary);
			box-shadow:
				0 0.2rem 0.75rem 0 color(secondary, dark),
				0 0.25rem 1rem 0 color(secondary, dark);
		}
	}
}

mat-select.settings-navigation--select {
	background-color: color(primary);
	padding: 0;
	border-radius: 5rem;
	min-height: 3rem;
	//max-height: 3rem;
	width: 20%;
	&:hover {
		background-color: color(secondary);
		border-color: color(primary);

		.mat-mdc-select-trigger {
			color: color(light, light);

			div.mat-mdc-select-arrow-wrapper {
				color: color(light, light);
			}
		}
	}

	.mat-mdc-select-trigger {
		position: relative !important;

		div.mat-mdc-select-value {
			position: relative;
			color: color(light, light);
			font-weight: 600;
		}
	}
}

.linear-select {
	position: relative;
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	mat-select {
		background-color: transparent;
		min-height: 1.25rem;
		max-height: 1.25rem;
		padding: 0;
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		.mat-select-min-line {
			color: color(secondary);
			border-bottom: 1px solid color(secondary);
		}
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		.mat-select-trigger {
			padding: 0;
			/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
			/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
			/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
			/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
			.mat-select-value {
				position: relative;
				/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
				/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
				.mat-select-value-text {
					position: relative;
					top: 4px;
				}
			}
		}
	}
}

.select-time .input-container {
	position: relative;
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
	mat-select {
		background-color: transparent;
		width: 9rem !important;
		position: relative;
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		.mat-select-trigger {
			background-color: white;
			border-radius: 0.5rem;
			border: 1px solid color(primary);
			color: color(text);
			margin-left: 0.5rem;
			position: absolute !important;
			top: -5px;
		}
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
	*/
		.mat-select-min-line {
			color: color(text);
		}
	}
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
	background-color: color(primary) !important;
}

/*
	TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
	*/
.mat-form-field-suffix {
	transform: translate(4px, 3px);
}

/*
	TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
	*/
.mat-form-field-wrapper {
	padding: 0px !important;
}

/*
	TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
	*/
.mat-form-field-appearance-fill .mat-form-field-flex {
	padding: 0.2em 0.75em 0 0.75em !important;
}

/*
	TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
	*/
.mat-form-field-flex {
	background-color: #f1f3f8 !important;
	border-radius: 0.5rem !important;
}

/*
	TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
	*/
.mat-form-field-underline {
	display: none !important;
}

.grid-two {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr 1fr;
	// @include res-above(medium) {
	// 	grid-template-columns: 1fr;
	//   }
}

.grid-three {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr 1fr 1fr;
	// @include res-above(medium) {
	// 	grid-template-columns: 1fr;
	//   }
}

// .grid-three {
//   align-items: center;
// 	display: flex;
// 	justify-content: space-between;

// 	.input {
// 		margin-left: 1rem;
// 		&:first-of-type {
// 			margin-left: 0;
// 		}
// 	}
// }

.input {
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: grid;
	grid-template-columns: 1fr;
	align-content: flex-start;

	input.custom-input {
		max-height: 3rem;
		min-height: 3rem;
	}
}

.center {
	padding: 1rem;
	justify-content: center;
	display: flex;
	text-align: center;
}

.avatar {
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
	object-fit: cover;
	cursor: pointer;
}

.grid-third {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 0.5rem;
	justify-content: space-between;
}

.grid-forth {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 0.5rem;
	justify-content: space-between;
}

.dropmenu {
	button {
		display: flex;
		align-items: center;
		span {
			margin-right: 0.5rem;
		}
	}
}

.green {
	color: #2dbc80;
}

// ---------------------------------- SVG styles ---------------------------------
div.card.print-icon-container {
	position: relative;
	width: fit-content;
	height: fit-content;
	padding: 0.5rem;
	border-radius: 1rem;
	border-width: 0.4rem;
	border-color: color(primary, base);
	background-color: color(light, light);

	&:hover {
		border-color: color(light, light);
		background-color: color(secondary, light);
	}

	div.card-content.print--icon {
		width: 1.5rem;
		height: 1.5rem;
	}
}

button.btn-notifications {
	.path-fill {
		fill: white;
	}

	&.active {
		.path-fill {
			fill: color(primary);
		}
	}
}

.btn--add-new {
	path.icon--add--path-stroke {
		stroke: white;
	}

	&:hover {
		path.icon--add--path-stroke {
			stroke: color(primary);
		}
	}
}

.icon--select-triangle {
	path.icon--select-triangle--path-stroke {
		stroke: color(secondary);
	}

	&.invalid {
		path.icon--select-triangle--path-stroke {
			stroke: color(warning);
		}
	}
}

.custom-select-container {
	.icon--select-triangle {
		path.icon--select-triangle--path-stroke {
			stroke: white;
		}

		&.invalid {
			path.icon--select-triangle--path-stroke {
				stroke: color(warning);
			}
		}
	}
}

app-header-user-info {
	height: 100%;
}

app-header-menu {
	height: 100%;
}

// ---------------------------- MATERIAL OVERRIDES -----------------------------

// MAT checkbox (checkouts) -------------
.generic-checkout--checkboxes mat-checkbox {
	margin-right: 0.25rem;
}
.bottom-tasks {
	.bottom-list {
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		.mat-mdc-checkbox-label {
			line-height: 1.2 !important;
		}
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		.mat-mdc-checkbox-layout {
			white-space: normal !important;
		}
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		.mat-checkbox-inner-container {
			margin-top: 4px !important;
		}
	}
}

// MAT TOOLTIP -------------
.mat-mdc-tooltip {
	font-size: 0.85rem;
	white-space: pre-line;
}

// MAT RADIO -------------
/*
	TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.
	*/
.mat-radio-label {
	align-items: center !important;
	display: flex !important;
	padding-right: 0.75rem !important;
	margin: 0.25rem 0 0.25rem !important;
}
/*
	TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.
	*/
.mat-radio-inner-circle {
	border-color: color(text, light);
}
/*
	TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.
	*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
	background-color: color(primary);
}

.mat-mdc-radio-button .mdc-form-field .mdc-label {
	margin: 0 0 0.25rem;
	padding-left: 0;
	color: color(text);
}

/*
	TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.
	*/
.mdc-radio__background .mdc-radio__outer-circle {
	border-color: color(text, light);
	border-width: 1px;
	background-color: color(light);
}
/*
	TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.
	*/
.mat-mdc-radio-button
	.mdc-radio
	.mdc-radio__native-control:enabled:checked
	+ .mdc-radio__background
	.mdc-radio__outer-circle {
	border-color: color(primary) !important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
	border-color: color(primary) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
	border-color: color(primary) !important;
}
/*
	TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.
	*/
.mat-radio-label-content {
	color: color(text);
	font-size: 0.75rem;
	padding-left: 0.25rem;
}
.mat-ripple-element {
	background-color: color(primary) !important;
}

// MAT LABEL -------------
label.input-label,
.input-container label,
label.input-label,
.input-container mat-label {
	color: color(primary);
	display: block;
	font-size: 0.85rem;
	font-weight: 700;
	margin: 1.5rem 0 0.25rem;

	@include res-below($medium) {
		margin: 0.5rem 0 0.25rem;
		font-size: 0.75rem;
	}
}

app-instructor-dashboard {
	app-autocomplete-input {
		label.flex-label {
			//color: white;
		}

		p span {
			padding: 0.15rem;
			border-radius: 2rem;
			background-color: white;
			font-weight: bold;
		}
	}
}

// TABS -------------

/*
	TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
	*/
.mat-tab-body-content {
	min-height: 5rem !important;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar {
	background-color: color(primary);
}

/*
	TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
	*/
.mat-tab-label-active .mat-tab-label-content {
	font-weight: 600;
	color: color(primary);
}

div.mdc-tab--active.mdc-tab-indicator--active {
	.mdc-tab__content {
		.mdc-tab__text-label {
			font-weight: 600;
			color: color(primary);
		}
	}

	.mdc-tab-indicator {
		span.mdc-tab-indicator__content {
			&.mdc-tab-indicator__content--underline {
				border-color: color(primary);
			}
		}
	}
}

// CHECKBOX -------------
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
mat-checkbox {
	border-color: color(primary);
	cursor: default !important;
}

/*
	.mdc-checkbox__background {
		border-color: color(primary);
		background: color(primary) !important;
	}
	*/

/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
.mat-checkbox-frame {
	border-color: color(primary);
	border-color: color(text, light);
}
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
.mat-mdc-checkbox-layout {
	margin: 0.1rem 0.25rem 0.1rem 0.1rem !important;
	font-weight: 400 !important;
	cursor: pointer !important;
}
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
.mat-checkbox-layout .mat-checkbox-inner-container .mat-ripple .mat-ripple-element {
	background: color(primary) !important;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled ~ .mdc-checkbox__background {
	background: color(primary) !important;
	border-color: color(primary) !important;
}
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background: color(primary) !important;
}
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/

.mat-mdc-checkbox {
	color: color(text);
	border-color: color(primary);
	cursor: default !important;

	.mdc-form-field {
		display: flex;
		text-wrap: nowrap;
		color: color(text);
		font-family: inherit;
		font-size: 0.75rem;
		font-weight: 800;

		label.mdc-label {
			color: color(text);
			display: contents;
		}
	}
}

/*.mdc-form-field {

		.mdc-checkbox {

			.mdc-label {
				color: color(text);
				font-size: .75rem;
				font-weight: 700;
			}
		}
	}*/

.mat-checkbox-label {
	color: color(text);
	font-size: 0.75rem;
	font-weight: 700;
}
#requirement-modal .info-division {
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	mat-checkbox {
		max-width: 100% !important;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		.mat-checkbox-layout {
			max-width: 100% !important;
			display: inline !important;
		}
	}
}

app-checkbox-input {
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	.mat-checkbox-inner-container {
		height: 1.5rem !important;
		width: 1.5rem !important;
	}
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	span.mat-checkbox-label {
		color: color(primary);
		font-size: 0.85rem;
		font-weight: 700;
		margin: 1.5rem 0 0.25rem;
	}
}

.checkboxes-container--double-line {
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	span.mat-checkbox-label {
		line-height: 1.5 !important;
	}
}

#leaseback {
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	.mat-checkbox-disabled {
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		.mat-checkbox-label {
			color: color(primary) !important;
			/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
			/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
			&:not(.mat-checkbox-checked) .mat-checkbox-frame {
				border-color: color(primary) !important;
			}
		}
	}
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
	.mat-checkbox-label {
		line-height: 1.3 !important;
	}
	span.last-updated {
		display: block !important;
		font-weight: 400 !important;

		strong {
			font-weight: 600 !important;
		}
	}
}

.transfer-credits-modal {
	h3.title {
		margin: 1.5rem 0 0 !important;
	}

	.input-container {
		margin-top: 1rem !important;
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
		mat-checkbox {
			max-width: 100%;
			/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
			/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
			/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
			/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
			.mat-checkbox-layout {
				align-items: center;
				display: flex;
				/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
				/*
	TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
	*/
				.mat-checkbox-label {
					display: inline-block;
					max-width: 100%;
					white-space: break-spaces;
					line-height: 1;
					margin: 0 !important;
					padding: 0 !important;
					color: color(text) !important;
					font-size: 0.8rem !important;
				}
			}
		}
	}
}

// SNACKBAR -------------
.mat-mdc-snack-bar-container {
	div.mdc-snackbar__surface {
		background: color(primary);
		border-radius: 0.5rem;
		box-sizing: border-box;
		//box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0.2);
		min-height: 4.5rem;
		display: block;
		overflow: hidden;
		padding: 0;
		transform-origin: center;

		width: 95%;
		margin: 2rem;
		max-width: 30rem;
		min-width: 30rem;

		@include res-above(medium) {
			max-width: 35rem;
			margin: 4rem 2rem;
			min-width: 25rem;
			width: 30vw;
		}
	}

	&.warning {
		div.mdc-snackbar__surface {
			background: color(warning);
		}
	}

	&.success {
		div.mdc-snackbar__surface {
			background: color(success);
		}
	}
}

// MAT-MENU -------------

/*button.mat-mdc-focus-indicator.mat-mdc-menu-item {
		max-width: 14rem !important;

		span {
			overflow: visible !important;
			text-overflow: ellipsis !important;
			white-space: nowrap !important;
		}
	}*/
.mat-mdc-menu-content {
	button.mat-mdc-menu-item {
		//padding-left: 1rem;
		//padding-right: 1rem;

		.mat-mdc-menu-item-text {
			font-size: 0.9rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
		}
	}
}

.mat-mdc-menu-panel {
	border-radius: 1rem !important;
	max-width: 14rem !important;
	min-width: 14rem !important;
	min-height: auto !important;
	box-shadow:
		0 0 0.5rem 0 rgb(0 0 0 / 15%),
		0 0 0.75rem 0.75rem rgb(0 0 0 / 5%);

	.mat-mdc-menu-content {
		padding: 0 !important;
		/*
	TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.
	*/
		/*
	TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.
	*/
		button.schedule--mat-menu-button {
			align-items: center;
			display: flex;
			justify-content: flex-start;

			&[disabled] {
				cursor: not-allowed !important;

				img {
					opacity: 0.75;
				}
			}

			img {
				max-width: 1rem;
				margin-right: 0.5rem;
			}

			.mat-mdc-menu-item-text {
				font-family: inherit;
				font-size: 0.9rem;
				font-weight: 400;
				line-height: inherit;
				letter-spacing: inherit;
			}
		}
	}
}

// DATEPICKER -------------

input.mat-datepicker-input.custom-input {
	min-height: auto;
}

.input-container.date-input {
	.date-input-container {
		position: relative;

		mat-datepicker-toggle.mat-datepicker-toggle {
			height: 3rem;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;

			.mat-mdc-icon-button {
				position: absolute;
				top: 0;
				right: 0;
				// background-color: rgba(0, 0, 255, .5);
				width: 100%;
				height: 3rem;
				border-radius: 0;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding: 0.5rem;
			}

			.mat-ripple {
				display: none;
			}
			/*
	TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
	*/
			span.mat-button-focus-overlay {
				background-color: transparent !important;
			}

			svg.mat-datepicker-toggle-default-icon {
				path {
					fill: color(primary) !important;
				}
			}
		}
	}
}

button.reservation-date-button {
	mat-datepicker-toggle {
		border-radius: 2rem;
		height: 2.25rem;
		position: absolute;
		top: 0;
		width: 9.5rem;
	}

	button.mat-mdc-icon-button {
		display: none !important;
	}
}

.mat-calendar-body-selected {
	background-color: color(secondary) !important;
}

// MODALS -------------

div.mat-mdc-dialog-surface {
	&.mdc-dialog__surface {
		border-radius: 2rem;
	}
}

.modal-secondary,
.modal-primary,
.final-modal {
	/*
	TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
	*/
	mat-dialog-container {
		border-radius: 1rem;
		margin: 0;
		max-height: 80vh;
		max-width: 50rem;
		min-width: 19rem;
		padding: 0;
		width: 55vw;

		@include res-below($medium) {
			//max-width: 70rem;
			width: 100%;
		}
	}

	&.modal-big {
		/*
	TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
	*/
		mat-dialog-container {
			width: 75vw;
			max-width: 75rem;
			min-width: 19rem;
		}
	}

	&.modal-bigger {
		/*
	TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
	*/
		mat-dialog-container {
			width: 85vw;
			max-width: 85rem;
			// min-width: 19rem;
			min-width: 70.5rem;
		}
	}

	&.group-modal-big {
		/*
	TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
	*/
		mat-dialog-container {
			height: 80vh !important;
			max-height: 80vh !important;
			min-height: 80vh !important;
		}
	}

	&.modal-tall {
		/*
	TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
	*/
		mat-dialog-container {
			max-height: 90rem;
		}
	}
	&.modal-xl {
		max-width: 90vw !important;
		/*
	TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
	*/
		mat-dialog-container {
			max-width: 90vw !important;
			min-width: 90vw !important;
			width: 90vw !important;
		}
	}
}

.modal-thin {
	/*
	TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
	*/
	mat-dialog-container {
		max-width: 30rem;
	}
}

.final-modal {
	/*
	TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
	*/
	mat-dialog-container {
		max-width: 120rem;
		width: 100vw;
	}
}

// ----------------------------------- GANTT -----------------------------------

.gstc-wrapper {
	.gstc {
		.gstc__list {
			border-right: 1px solid color(neutral);

			.gstc__list-column {
				.gstc__list-column-header {
					border-bottom: 1px solid color(neutral);

					.gstc__list-column-header-content {
						.gstc__list-column-row-expander {
							.gstc__list-column-row-expander-toggle {
								.gstc__list-column-row-expander-toggle-open,
								.gstc__list-column-row-expander-toggle-closed {
									display: none;
								}
							}
						}

						.gstc__list-column-header-resizer {
							// .gstc__list-column-header-resizer-container {

							// }

							.gstc__list-column-header-resizer-dots {
								//display: none !important;
								//Added css
								align-items: flex-start;

								.gstc__list-column-header-resizer-dots-dot {
									background-color: #e76831;
								}
							}
						}
					}
				}

				// .gstc__list-column-rows {

				// 	.gstc__list-column-rows-offset {

				// 		.gstc__list-column-row {

				// 			.gstc__list-column-row-expander {

				// 				.gstc__list-column-row-expander-toggle {

				// 					.gstc__list-column-row-expander-toggle-child {

				// 					}
				// 				}
				// 			}

				// 			.gstc__list-column-row-content {

				// 			}
				// 		}
				// 	}
				// }
			}
		}

		.gstc__chart {
			.gstc__chart-calendar {
				border-bottom: 1px solid color(neutral);

				.gstc__chart-calendar-dates {
					cursor: pointer;

					//Added CSS properties
					//modified
					//width: fit-content;
					//width: 100%;
					//border-radius: 1rem;

					&.gstc__chart-calendar-dates--level-0 {
						background-color: color(neutral);

						.gstc__chart-calendar-date--day {
							&:nth-of-type(2) {
								display: none !important;
							}
						}

						.gstc__chart-calendar-date-content {
							align-items: center;
							color: color(dark);
							display: flex;
							font-size: 0.9rem;
							cursor: default;

							&::after {
								background-image: url("./assets/img/menu/menu_schedule_off.svg");
								background-position: center;
								background-repeat: no-repeat;
								content: "";
								display: inline-block;
								height: 1rem;
								margin-left: 0.5rem;
								min-width: 1rem;
								width: 1rem;
							}
						}
					}

					/*&.gstc__chart-calendar-dates--level-1 {

							div.gstc__chart-calendar-date {

								&.gstc__chart-calendar-date--hour {

									&.gstc__chart-calendar-date--main-date {

										&.gstc__chart-calendar-date--level-1 {
											width: calc((100% / 19)) !important;
										}
									}
								}

							}
						}*/

					.gstc__chart-calendar-date {
						justify-content: center;
						font-size: 1rem;
						font-weight: 700;
						//width: inherit !important;

						// .gstc__chart-calendar-date-content {

						// }
					}
				}
			}

			.gstc__chart-timeline {
				.gstc__chart-timeline-inner {
					.gstc__chart-timeline-grid {
						.gstc__chart-timeline-grid-row {
							.gstc__chart-timeline-grid-row-cell {
								position: relative;
								//width: inherit !important;

								&.gstc__selected,
								&.gstc__selecting {
									background-color: white !important;
								}

								&::after,
								&::before {
									border-left: 1px solid rgba(237, 241, 242, 0.75);
									border-right: 1px solid rgba(237, 241, 242, 0.75);
									content: "";
									height: 100%;
									left: 0;
									position: absolute;
									top: 0;
									width: 50%;
								}

								&::before {
									border-left: 1px dotted rgba(237, 241, 242, 0.75);
									border-right: 1px dotted rgba(237, 241, 242, 0.75);
									left: 25%;
									width: 50%;
								}

								&.current {
									background-color: color(background) !important;
								}
							}
						}
					}

					.gstc__chart-timeline-items {
						.gstc__chart-timeline-items-row {
							// &:hover {
							// 	overflow: visible !important;

							// 	.gstc__chart-timeline-items-row-item {
							// 		overflow: visible !important;
							// 		max-width: 100%;
							// 	}
							// 	.gstc__chart-timeline-items-row-item-label {
							// 		overflow: visible !important;
							// 		max-width: 100%;
							// 	}
							// }

							.gstc__chart-timeline-items-row-item {
								background-color: color(res-reserved);
								border-radius: 14px;
								height: 41px !important;
								overflow: hidden;

								&.gstc__selected {
									box-shadow: 0px 0px 1px 2px color(res-reserved, dark) !important;
								}
								.gstc__chart-timeline-items-row-item-cut,
								.gstc__chart-timeline-items-row-item-resizing-handle {
									background-color: color(res-reserved, dark);
								}

								&.item--status-0 {
									// 1 - En progreso // Check out

									&.item-mine {
										border-color: color(primary);
										border-style: solid;
										border-width: medium;
									}
								}

								&.item--status-cannot-fly {
									// NOT ALLOWED / NOT CLEARED (client and/or instructor)
									background-color: color(res-nofly);
									&.gstc__selected {
										box-shadow: 0px 0px 1px 2px color(res-nofly, dark) !important;
									}
									.calendar-item {
										background-color: color(res-nofly);
									}
									.gstc__chart-timeline-items-row-item-cut,
									.gstc__chart-timeline-items-row-item-resizing-handle {
										background-color: color(res-nofly, dark);
									}

									&.item-mine {
										border-color: color(primary);
										border-style: solid;
										border-width: medium;
									}
								}

								&.item--status-1 {
									// 1 - En progreso // Check out
									background-color: color(res-progress);
									&.gstc__selected {
										box-shadow: 0px 0px 1px 2px color(res-progress, dark) !important;
									}
									.calendar-item {
										background-color: color(res-progress);
									}
									.gstc__chart-timeline-items-row-item-cut,
									.gstc__chart-timeline-items-row-item-resizing-handle {
										background-color: color(res-progress, dark);
									}

									&.item-mine {
										border-color: color(primary);
										border-style: solid;
										border-width: medium;
									}
								}

								&.item--status-2 {
									// 2 - Check in
									background-color: color(res-graded);
									&.gstc__selected {
										box-shadow: 0px 0px 1px 2px color(res-graded, dark) !important;
									}
									.calendar-item {
										background-color: color(res-graded);
									}
									.gstc__chart-timeline-items-row-item-cut,
									.gstc__chart-timeline-items-row-item-resizing-handle {
										background-color: color(res-graded, dark);
									}

									&.item-mine {
										border-color: #050505;
										border-style: solid;
										border-width: medium;
									}
								}

								&.item--status-3 {
									// 3 - Cancelled
									background-color: color(secondary);
									&.gstc__selected {
										box-shadow: 0px 0px 1px 2px color(secondary, dark) !important;
									}
									.calendar-item {
										background-color: color(secondary);
									}
									.gstc__chart-timeline-items-row-item-cut,
									.gstc__chart-timeline-items-row-item-resizing-handle {
										background-color: color(secondary, dark);
									}

									&.item-mine {
										border-color: color(primary);
										border-style: solid;
										border-width: medium;
									}
								}

								&.item--status-4 {
									// 4 - Graded
									background-color: color(quaternary);
									&.gstc__selected {
										box-shadow: 0px 0px 1px 2px color(quaternary, dark) !important;
									}
									.calendar-item {
										background-color: color(quaternary);
									}
									.gstc__chart-timeline-items-row-item-cut,
									.gstc__chart-timeline-items-row-item-resizing-handle {
										background-color: color(quaternary, dark);
									}

									&.item-mine {
										border-color: color(primary);
										border-style: solid;
										border-width: medium;
									}
								}

								&.item--status-5 {
									// 5 - missing invoice
									background-color: color(res-invoice);
									&.gstc__selected {
										box-shadow: 0px 0px 1px 2px color(res-invoice, dark) !important;
									}
									.calendar-item {
										background-color: color(res-invoice);
									}
									.gstc__chart-timeline-items-row-item-cut,
									.gstc__chart-timeline-items-row-item-resizing-handle {
										background-color: color(res-invoice, dark);
									}

									&.item-mine {
										border-color: color(primary);
										border-style: solid;
										border-width: medium;
									}
								}

								&.item--status-6 {
									// 6 - completed
									background-color: color(res-completed);
									&.gstc__selected {
										box-shadow: 0px 0px 1px 2px color(res-completed, dark) !important;
									}
									.calendar-item {
										background-color: color(res-completed);
									}
									.gstc__chart-timeline-items-row-item-cut,
									.gstc__chart-timeline-items-row-item-resizing-handle {
										background-color: color(res-completed, dark);
									}

									&.item-mine {
										border-color: color(primary);
										border-style: solid;
										border-width: medium;
									}
								}

								&.item--status-7 {
									// 7 - pre-enroll
									background-color: color(res-pre-enroll);
									&.gstc__selected {
										box-shadow: 0px 0px 1px 2px color(res-completed, dark) !important;
									}
									.calendar-item {
										background-color: color(res-pre-enroll);
									}
									.gstc__chart-timeline-items-row-item-cut,
									.gstc__chart-timeline-items-row-item-resizing-handle {
										background-color: color(res-pre-enroll, dark);
									}

									&.item-mine {
										border-color: color(primary);
										border-style: solid;
										border-width: medium;
									}
								}

								&.item--status-8 {
									// 8 - other location
									background-color: color(res-other-location);
									&.gstc__selected {
										box-shadow: 0px 0px 1px 2px color(res-completed, dark) !important;
									}
									.calendar-item {
										background-color: color(res-other-location);
									}
									.gstc__chart-timeline-items-row-item-cut,
									.gstc__chart-timeline-items-row-item-resizing-handle {
										background-color: color(res-other-location, dark);
									}

									&.item-mine {
										border-color: color(primary);
										border-style: solid;
										border-width: medium;
									}
								}

								&.client-inactive {
									background-color: color(gray);
									&.gstc__selected {
										box-shadow: 0px 0px 1px 2px color(gray, dark) !important;
									}
									.calendar-item {
										background-color: color(gray);
									}
									.gstc__chart-timeline-items-row-item-cut,
									.gstc__chart-timeline-items-row-item-resizing-handle {
										background-color: color(gray, dark);
									}
								}

								&.gstc__chart-timeline-items-row-item--right-cut {
									.calendar-item--options {
										display: none !important;
									}
								}

								.gstc__chart-timeline-items-row-item-label {
									margin: 0 !important;
									// -webkit-mask-image: linear-gradient(to right, #000 0%, #000 75%, transparent 90%);
									// mask-image: linear-gradient(to right, #000 0%, #000 75%, transparent 90%);
									-webkit-mask-image: none;
									mask-image: none;
									border-radius: 14px;
								}

								.gstc__chart-timeline-items-row-item-resizing-handle {
									height: 100% !important;
								}
							}
						}
					}
				}
			}

			// .gstc__scroll-bar {

			// 	.gstc__scroll-bar-inner {

			// 	}
			// }
		}
	}
}

.no-items-message {
	font-size: 1.125rem;
	margin: 3rem;
	text-align: center;
}

span.obligatory {
	color: color(warning);
}

p.error-message {
	color: color(warning);
	font-size: 0.75rem;
	margin: 0;
	text-align: right;
	margin-top: 0.5rem;
}

p.no-items-message {
	margin: 2rem auto;
	font-size: 1.125rem;
	text-align: center;
}

.display-none {
	display: none !important;
}

.material-icons.go-back--arrow {
	position: absolute;
	left: 2rem;
	top: 1rem;
	font-size: 2.1rem;
	color: color(primary);
	cursor: pointer;
}

#requirements-container {
	/*
	TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
	*/
	.mat-tab-labels {
		max-width: 17rem !important;
		margin: 0 auto !important;
	}
}

.fix-scroll {
	overflow: hidden;
}

.enable-requirement-icon {
	background-color: color(success) !important;

	&:hover {
		background-color: color(success, dark) !important;
	}
}

.disable-requirement-icon {
	background-color: color(delete) !important;

	&:hover {
		background-color: color(delete, dark) !important;
	}
}

.chip {
	border-radius: 1.5rem;
	color: white;
	padding: 0.2rem 0.4rem;
	white-space: nowrap;
}

.pointer {
	cursor: pointer !important;
}
.display-none {
	display: none !important;
}

// .gstc__chart-timeline-grid-row, // testing height
// .gstc__list-column-row-content,
// .gstc__list-column-row {
// 	height: 50px !important;
// }

.ladda-button {
	font-size: 1rem !important;
}

.tasks-header .ladda-label {
	display: flex !important;
}

.webkit-password {
	-webkit-text-security: disc !important;
}

.item-name-container .input-container {
	align-items: center !important;
	display: flex !important;
	justify-content: space-between !important;

	label {
		color: rgba(0, 0, 0, 0.87) !important;
		font-size: 1rem !important;
		font-weight: 400 !important;
		margin: 0 1rem 0 0 !important;
		min-width: 5.1rem !important;
	}

	input {
		border: none !important;
		max-height: 2rem !important;
		max-width: 15rem !important;
		padding: 0.2rem 0.75rem !important;
	}
}

.small-invoice .item-name-container .input-container {
	label {
		font-size: 13px !important;
	}
}

.item-name-container {
	justify-content: space-between !important;
	width: 100%;

	app-autocomplete-input {
		width: 100% !important;
	}

	.invisible-style-div {
		flex: 1;
	}

	p.error-message {
		position: absolute;
		right: 0.5rem;
		bottom: 0;
		cursor: help;
	}
}

//----FORMATTING FOR PRINT----//
@media print {
	//--GENERAL PRINT FORMATTING--//
	div {
		overflow-x: visible !important;
		overflow-y: visible !important;
	}

	aside {
		display: none !important;
	}

	body {
		font-size: x-small !important;
		font-weight: 600 !important;

		/*height: 100% !important;
		min-height: fit-content !important;
		max-height: fit-content !important;

		width: 100% !important;
		min-width: fit-content !important;
		max-width: fit-content !important;*/
	}

	/*app-wrapper {
		display: block !important;
		width: 100% !important;
		min-width: 100% !important;
	}*/

	//MAIN
	main.single-content {
		overflow-x: visible !important;
		overflow-y: visible !important;
		min-height: unset !important;
		max-height: unset !important;
	}

	div#contents.contents {
		max-width: 100% !important;
		//min-width: fit-content !important;
		width: 100% !important;

		header.common-header {
			width: 100%;
			border-radius: 0rem;
			margin-bottom: unset;

			app-header-menu {
				display: none !important;
			}

			div.header-section {
				&.header-section--back {
					display: none !important;
				}
			}

			div.header-section.header-section--header-name {
				display: flex !important;
				flex-direction: column !important;
				height: fit-content !important;
				border-left: unset !important;

				h1 {
					font-weight: bold;
				}
			}

			app-header-user-info {
				display: none !important;
			}
		}

		nav.section-navigation {
			display: none !important;
		}
	}
}

.equipment-activity__paginator {
	.mat-mdc-text-field-wrapper.mdc-text-field {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.mat-mdc-select-trigger {
		padding: 0 1.25rem
	}

	.mat-mdc-select {
		padding: 0;
	}
}
