/* Responsiveness helpers */
$xxsmall: 320px;
$xsmall: 375px;
$small: 425px;
$medium-s: 600px;
$medium: 768px;
$medium-l: 900px;
$large: 1024px;
$xlarge: 1200px;
$xmlarge: 1350px;
$xxlarge: 1440px;
$xxxlarge: 1550px;

@mixin res-above($res) {
	@media screen and (min-width: $res) {
		@content;
	}
}

@mixin res-below($res) {
	@media screen and (max-width: $res) {
		@content;
	}
}

@mixin screen-portrait {
	@media screen and (orientation: portrait) {
		@content;
	}
}

@mixin screen-landscape {
	@media screen and (orientation: landscape) {
		@content;
	}
}
