@import './colors';

// ---------------------------------- BUTTONS ----------------------------------

button {
	outline: none;

	&:focus {
		outline-color: transparentize(color(primary), .8) !important;
		outline-style: solid !important;
		outline-width: 1px !important;
	}

	&.ladda-button {
		transition: padding-right 0.2s !important;
	}
}

button.btn {
	@extend .box-shadow !optional;
	border-radius: 5rem;
	border: 0.1rem solid transparent;
	font-weight: 500;
	height: min-content;
	max-height: 3rem;
	min-height: 3rem;
	padding: 0.5rem 1.25rem;
	transition: none;
	line-height: 1;

	&.btn-inactive {
		background-color: color(inactive);
		color: color(text);
		border: 0.1rem solid transparent;
		font-size: 1rem;
	}

	&.btn-small {
		box-shadow: 0 0.25rem 0.25rem -0.05rem #c7c8ca;
		font-size: .8rem;
		padding: .15rem .5rem;
		min-height: auto;
	}

	&.btn-medium {
		font-size: 1rem;
		padding: .5rem .5rem;
		min-height: auto;
	}

	&.btn-primary {
		background-color: color(primary);
		border-color: color(primary);
		color: white;

		&:hover {
			background-color: color(primary, dark);
			border-color: color(primary, dark);
		}

		&.active {
			cursor: default;
			background-color: color(primary);
		}

		&.light {
			background-color: color(primary, light);
			border-color: color(primary, light);
			color: white;
			&:hover {
				background-color: color(primary);
				border-color: color(primary);
			}
		}

		&.btn-primary--dark {
			background-color: color(primary, darker) !important;
			border-color: color(primary, darker) !important;
			box-shadow: none !important;
			color: white !important;
			&:hover {
				background-color: color(primary, darkest) !important;
				border-color: color(primary, darkest) !important;
			}
		}
	}

	&.btn-primary-important {
		background-color: color(primary) !important;
		border-color: color(primary) !important;
		color: white !important;

		&:hover {
			background-color: color(primary, dark) !important;
			border-color: color(primary, dark) !important;
		}

		&.active {
			cursor: default !important;
			background-color: color(primary) !important;
		}
	}

	&.btn-secondary {
		background-color: color(secondary);
		border-color: color(secondary);
		color: white;

		&:hover {
			background-color: color(secondary, dark);
			border-color: color(secondary, dark);
		}

		&.active {
			cursor: default;
			background-color: color(secondary);
		}
	}

	&.btn-secondary-important {
		background-color: color(secondary) !important;
		border-color: color(secondary) !important;
		color: white !important;

		&:hover {
			background-color: color(secondary, dark) !important;
			border-color: color(secondary, dark) !important;
		}

		&.active {
			cursor: default !important;
			background-color: color(secondary) !important;
		}
	}

	&.btn-success {
		background-color: color(success);
		border-color: color(success);
		color: white;

		&:hover {
			background-color: color(success, dark);
			border-color: color(success, dark);
		}

		&.active {
			cursor: default;
			background-color: color(success);
		}
	}

	&.btn-success-important {
		background-color: color(success) !important;
		border-color: color(success) !important;
		color: white !important;

		&:hover {
			background-color: color(success, dark) !important;
			border-color: color(success, dark) !important;
		}

		&.active {
			cursor: default !important;
			background-color: color(success) !important;
		}
	}

	&.btn-warning {
		background-color: color(warning);
		border-color: color(warning);
		color: white;

		&:hover {
			background-color: color(warning, dark);
			border-color: color(warning, dark);
		}

		&.active {
			cursor: default;
			background-color: color(warning);
		}
	}

	&.btn-warning-important {
		background-color: color(warning) !important;
		border-color: color(warning) !important;
		color: white !important;

		&:hover {
			background-color: color(warning, dark) !important;
			border-color: color(warning, dark) !important;
		}

		&.active {
			cursor: default !important;
			background-color: color(warning) !important;
		}
	}
	&.btn-rejected {
		background-color: color(rejected);
		border-color: color(rejected);
		color: white;

		&:hover {
			background-color: color(rejected, dark);
			border-color: color(rejected, dark);
		}

		&.active {
			cursor: default;
			background-color: color(rejected);
		}
	}

	&.btn-rejected-important {
		background-color: color(rejected) !important;
		border-color: color(rejected) !important;
		color: white !important;

		&:hover {
			background-color: color(rejected, dark) !important;
			border-color: color(rejected, dark) !important;
		}

		&.active {
			cursor: default !important;
			background-color: color(rejected) !important;
		}
	}

	&.btn-success {
		background-color: color(success);
		border-color: color(success);
		color: white;

		&:hover {
			background-color: color(success, dark);
			border-color: color(success, dark);
		}

		&.active {
			cursor: default;
			background-color: color(success);
		}
	}

	&.btn-success-important {
		background-color: color(success) !important;
		border-color: color(success) !important;
		color: white !important;

		&:hover {
			background-color: color(success, dark) !important;
			border-color: color(success, dark) !important;
		}

		&.active {
			cursor: default !important;
			background-color: color(success) !important;
		}
	}

	&.btn-tertiary {
		background-color: transparent;
		border-color: white;
		color: white;

		&:hover {
			background-color: white;
			color: color(primary);
		}
	}

	&.btn-expired {
		background-color: color(expired);
		border-color: color(expired);
		color: white;

		&:hover {
			background-color: color(expired, dark);
			border-color: color(expired, dark);
		}

		&.active {
			cursor: default;
			background-color: color(expired);
		}
	}

	&.btn-expired-important {
		background-color: color(expired) !important;
		border-color: color(expired) !important;
		color: white !important;

		&:hover {
			background-color: color(expired, dark) !important;
			border-color: color(expired, dark) !important;
		}

		&.active {
			cursor: default !important;
			background-color: color(expired) !important;
		}
	}

	&[disabled] {
		cursor: not-allowed;
		background-color: color(gray);
		border-color: color(gray);
		color: white;
		pointer-events: auto !important;
		opacity: 1 !important;

		&:hover {
			background-color: color(gray);
			border-color: color(gray);
		}
	}

	&.btn--add-new {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 8rem;

		&:hover {
			color: color(primary, dark) !important;
			background-color: white !important;
			border-color: white !important;
		}

		.icon--add {
			display: flex;
			max-width: 1.25rem;
			margin-right: .75rem;
		}
	}

	&.btn-centered {
		display: block;
		margin: 3rem auto 1rem;
	}
}

button.btn-text {
	background-color: transparent;
	border-bottom: .1rem solid color(text);
	color: color(text);
	outline: none;
	padding: .5rem 0;

	&:hover {
		color: color(text, dark);
	}

	&:focus {
		outline-color: transparentize(color(primary), .8) !important;
		outline-style: solid !important;
		outline-width: 1px !important;
	}
}

button.btn-squared {
	background-color: color(light, dark);
	color: color(text);
	outline: none;
	padding: 1rem 1.5rem;
	border-radius: .5rem;
	
	&:hover {
		background-color: color(neutral, light);
	}
	
	&.active {
		background-color: color(secondary);
		color: white;
	}

	&.invalid {
		border: 1px solid color(warning);
	}
}

button.btn-icon {
	// Positioning
	width: 2rem;
	max-width: 2rem;
	min-width: 2rem;
	height: 2rem;
	max-height: 2rem;
	min-height: 2rem;
	
	// Box model
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0 0.5rem;
	
	// Typography
	color: white;
	font-size: 1.2rem;
	
	// Visual
	border-radius: 50%;
	
	// Misc
	background-color: color(primary);
	cursor: pointer;
	
	&.small {
		width: 1.5rem;
		max-width: 1.5rem;
		min-width: 1.5rem;
		height: 1.5rem;
		max-height: 1.5rem;
		min-height: 1.5rem;
	}
	
	&.medium {
		width: 2.5rem;
		max-width: 2.5rem;
		min-width: 2.5rem;
		height: 2.5rem;
		max-height: 2.5rem;
		min-height: 2.5rem;
	}
	
	&.big {
		width: 3rem;
		max-width: 3rem;
		min-width: 3rem;
		height: 3rem;
		max-height: 3rem;
		min-height: 3rem;
	}
	
	&:hover {
		background-color: color(primary, dark);
	}
	
	&.secondary {
		background-color: color(secondary);
		
		&:hover {
			background-color: color(secondary, dark);
		}
	}
	
	&.transparent-primary {
		background-color: transparent;
		
		&:hover {
			background-color: color(primary, dark);
		}
	}
	
	&.success {
		background-color: color(success);

		&:hover {
			background-color: color(success, dark);
		}
	}
	
	&.warning {
		background-color: color(warning);
		color: white !important;

		&:hover {
			background-color: color(warning, dark);
		}
	}
	
	&.delete {
		background-color: color(delete);

		&:hover {
			background-color: color(delete, dark);
		}
	}
	
	&.accent {
		background-color: color(accent);

		&:hover {
			background-color: color(accent, dark);
		}
	}
	
	&.contrast {
		background-color: color(contrast);

		&:hover {
			background-color: color(contrast, dark);
		}
	}
	
	&.gray {
		background-color: color(gray);

		&:hover {
			background-color: color(gray, dark);
		}
	}


}

.icon-buttons--container {
	display: flex;
	align-items: center;
	justify-content: center;

	button.btn-round-icon {
		align-items: center;
		background-color: color(secondary, light);
		border-radius: 50%;
		color: white;
		display: flex;
		height: 2rem;
		justify-content: center;
		margin-right: .5rem;
		max-height: 2rem;
		max-width: 2rem;
		min-height: 2rem;
		min-width: 2rem;
		width: 2rem;

		&:hover {
			background-color: color(secondary, dark);
			cursor: pointer;
		}

		span {
			font-size: 1rem;
		}
	}
}

.success-tag {
	border-radius: 5rem;
	font-weight: 500;
	height: min-content;
	max-height: 3rem;
	min-height: 3rem;
	padding: .5rem 1.25rem;
	color: color(success);
	background-color: rgba(33, 184, 121, .1);
	align-items: center;
	display: flex;
	justify-content: center;

	&:hover {
		background-color: rgba(33, 184, 121, .2);
	}

	&.active {
		background-color: color(success);
		color: white;

		&:hover {
			background-color: color(success, dark);
		}
	}
}

.btn-check:active+.btn-warning:focus, .btn-check:checked+.btn-warning:focus, .btn-warning.active:focus, .btn-warning:active:focus, .show>.btn-warning.dropdown-toggle:focus {
	box-shadow: none !important
}

	